import React, { useState, useEffect } from 'react'
import './Search.css'
import axios from 'axios'
import Details from '../Details/Details'

function Search() {
    var d = new Date()
    var date = d.toLocaleString('en-IN', { timeZone: "Asia/Kolkata" }).split(',')[0].split(':')
    var date_day = date[0].split('/')[0] < 10 ? 0 + date[0].split('/')[0] : date[0].split('/')[0]
    var date_month = date[0].split('/')[1] < 10 ? 0 + date[0].split('/')[1] : date[0].split('/')[1]
    var date_year = date[0].split('/')[2]
    var req_date = `${date_year}-${date_month}-${date_day}`
    const [showDate, setShowDate] = useState(req_date)
    const [details, setDetails] = useState('')
    const [state, setState] = useState('Kerala')
    const [district, setDistrict] = useState('Ernakulam')
    const [clicked, setClicked] = useState(false)
    const [updates, setupdates] = useState(0)


    const getData = () => {
        setClicked(true)
        axios.get(`https://api.ntools.tech/${process.env.REACT_APP_API_KEY}/${district}/${showDate}`)
            .then((res) => {
                if (res.data.details.length !== 0) {
                    setDetails(res.data)
                } else {
                    setDetails(null)
                }
                setClicked(false)
            }).catch((err) => console.log(err))
    }

    useEffect(() => {
        setClicked(true)
        axios.get(`https://api.ntools.tech/${process.env.REACT_APP_API_KEY}/${district}/${showDate}`)
            .then((res) => {
                if (res.data.details.length !== 0) {
                    setDetails(res.data)
                } else {
                    setDetails(null)
                }
                setClicked(false)
            }).catch((err) => console.log(err))
    }, [showDate])

    return (
        <div>
            <div className="row">
                <div className="col-md-4 pt-3">
                    <label>State</label>
                    <select className="form-select" onChange={(e) => setState(e.target.value)}>
                        <option defaultValue>Kerala</option>
                        {/* <option value="1">Thiruvananthapuram</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option> */}
                    </select>
                </div>
                <div className="col-md-4 pt-3">
                    <label>District</label>
                    <select className="form-select" onChange={(e) => {
                        setDistrict(e.target.value)
                        setDetails(false)
                        setClicked(false)
                    }}>
                        <option defaultValue>Ernakulam</option>
                        {/* <option defaultValue>Kasaragod</option> */}
                        {/* <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option> */}
                    </select>
                </div>
                <div className="col-md-4 pt-3">
                    <div className="form-group row">
                        <div className="col-10">
                            <label>Date</label>
                            <input className="form-control" type="date"
                                value={showDate} onChange={(e) => {
                                    setDetails(false)
                                    setShowDate(e.target.value)
                                    setClicked(false)
                                }} id="example-date-input"></input>
                        </div>
                    </div>
                </div>
                <div className="button_align text-center">
                    <button type="submit" className="btn btn btn-warning submit-btn-style" onClick={getData}>Search</button>
                </div>
            </div>
            {details === null ?
                <div className="mt-3 text-center" style={{ backgroundColor: 'rgb(197, 233, 162)', borderRadius: '20px', padding: '20px' }}>
                    <h4 style={{ color: 'red', fontFamily: 'Nunito' }}>
                        {details === null ? <hr /> : ''}
                        <b>
                            {details === null ? `No Covid Vaccination Centers are available at ${district} on ${showDate.split('-')[2] + '-' + showDate.split('-')[1] + '-' + showDate.split('-')[0]}` : ''}
                        </b>
                    </h4>
                </div> : ''
            }
            {clicked ? <div className='text-center mt-3'><div className="spinner-grow text-success" role="status"></div></div> : ''}
            {details && <Details data={details} date={showDate} />}
        </div>
    )
}

export default Search
