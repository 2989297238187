import './App.css';
import Home from './Pages/Home';
import MainHome from './Pages/MainHome'
import PrivacyPolicy from './Pages/PrivacyPolicy';
import SendWhatsappmessage from './Pages/SendWhatsappmessage'
import { BrowserRouter as Router, Route } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <Router>
        <Route exact path='/vaccine-by-district'>
          <Home />
        </Route> 
        <Route exact path='/'>
          <MainHome />
        </Route> 
        <Route exact path='/privacy-policy'>
          <PrivacyPolicy />
        </Route> 
        <Route exact path='/whatsapp-generate-link'>
          <SendWhatsappmessage />
        </Route> 
      </Router>
    </div>
  );
}

export default App;
