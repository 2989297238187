import React from 'react'
import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'
import PrivacyPolicy from '../Components/Privacy-Policy/PrivacyPolicy'

function Home() {
    return (
        <div>
            <Header />
            <div className="container">
                <PrivacyPolicy />
            </div>
            <Footer />
        </div>
    )
}

export default Home