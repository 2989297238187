import React from 'react'
import Header from '../Components/Header/Header'
import Search from '../Components/Search/Search'
import Footer from '../Components/Footer/Footer'

function Home() {
    return (
        <div>
            <Header />
            <div className="container">
                <Search />
            </div>
            <div style={{marginTop: '70vh'}}>
                <Footer />
            </div>
        </div>
    )
}

export default Home
