import React from 'react'
import Header from '../Components/Header/Header'
import Whatsapp from '../Components/SendWhatsappLink/Whatsapp'
import Footer from '../Components/Footer/Footer'

function Home() {
    return (
        <div>
            <Header />
            <div className="container">
                <Whatsapp />
            </div>
            <div style={{marginTop: '80vh'}}>
                <Footer />
            </div>
        </div>
    )
}

export default Home
