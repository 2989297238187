import React, { useState } from 'react'
import './Whatsapp.css'
import { IoIosCopy } from 'react-icons/io';

function Whatsapp() {
    const [phone, setPhone] = useState('')
    const [msg, setMsg] = useState('')
    const [uri, setUri] = useState('')
    const [show, setShow] = useState(false)
    const [copied, setCopied] = useState(false)

    const generate = () => {
        let uri = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(msg)}`
        setUri(uri)
        setShow(true)
        return
    }


    return (
        <div className='center pt-5'>
            {
                copied ? <div className="alert alert-success w-100 text-center" role="alert">
                Copied to clipboard
                </div> : ''
            }
            <div className="width-box">
                <div className="mb-3">
                    <label for="exampleInputEmail1" className="form-label">Phone Number</label>
                    <input type="number" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} pattern="[789][0-9]{9}" onkeypress="return event.charCode >= 48 && event.charCode <= 57" required></input>
                </div>
                <div className="mb-3">
                    <label for="exampleFormControlTextarea1" className="form-label">Message</label>
                    <textarea className="form-control" rows="3" value={msg} onChange={(e) => {
                        setMsg(e.target.value)
                        setShow(false)
                        // generate()
                    }} required></textarea>
                </div>
            </div>
            <button type="submit" className="btn btn-success sub-btn" onClick={generate}>Create Link</button>
            {
                show ? msg ? <div className='box' onClick={() => {
                    navigator.clipboard.writeText(uri)
                    .then(() => {
                        setCopied(true)
                        setTimeout(() => {
                            setCopied(false)
                        }, 4000)
                    })
                }}><p className='box-content'>{uri}</p><IoIosCopy/></div> : '' : ''
            }
            {
                show ? msg ? <div className='mt-3'><button type="submit" className="btn btn-secondary sub-btn" onClick={() => {
                    setMsg('')
                    setPhone('')
                    setShow(false)
                    setUri('')
                }}>Clear</button></div> : '' : ''
            }
        </div>
    )
}

export default Whatsapp
