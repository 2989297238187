import React from 'react'
import './Header.css'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function Header() {
    return (
        <div>
            <div className="contain">
                <Navbar collapseOnSelect expand="lg" style={{backgroundColor: '#563d7c'}}>
                    <Navbar.Brand href="#home"><span style={{ paddingLeft: '20px', color: 'white' }}>𝒏-𝒕𝒐𝒐𝒍𝒔</span></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className='toggle'/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <div className="contain" style={{ paddingLeft: '20px' }}>
                            <Nav className="mr-auto">
                                <Nav.Link><Link to="/" className='remove-link'><b>Home</b></Link></Nav.Link>
                                <Nav.Link><Link to="/vaccine-by-district" className='remove-link'><b>Vaccine Slot Availability Checker</b></Link></Nav.Link>
                                <Nav.Link><Link to="/whatsapp-generate-link" className='remove-link'><b>Whatsapp link Generator</b></Link></Nav.Link>
                                {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                                </NavDropdown> */}
                            </Nav>
                        </div>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </div>
    )
}

export default Header
