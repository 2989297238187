import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'

function Footer() {
    return (
        <div>
            <footer className='footer'>
                <hr />
                <div className="container">
                    <p class="copyright-text">Copyright © 2021 𝒏-𝒕𝒐𝒐𝒍𝒔 - All Rights Reserved.</p>
                    <br />
                    <div className="text-center">
                        <p><Link to='/privacy-policy' className='no-links'>Privacy Policy</Link></p>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer
