import React from 'react'
import './Details.css'

function Details(props) {
    var details = props.data.details
    var date = props.date.split('-')[2] + '-' + props.date.split('-')[1] + '-' + props.date.split('-')[0]
    return (
        <div className="pt-3">
            <hr />
            <h3 className='district_details text-center'>Covid Vaccination Centers at {props.data.district} on {date}</h3>
            <div className="big-screen">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col" className='table_heading'>Center</th>
                            <th scope="col" className='table_heading'>Timing</th>
                            <th scope="col" className='table_heading'>First Dose</th>
                            <th scope="col" className='table_heading'>Second Dose</th>
                            <th scope="col" className='table_heading'>Date</th>
                            <th scope="col" className='table_heading'>Vaccine</th>
                            <th scope="col" className='table_heading'>Fee</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            details.map((obj, key) => {
                                return (
                                    <tr key={key}>
                                        <th scope="row" className='center-i'>{obj.Name}<br /> <span className='small-font'>{obj.Address}</span></th>
                                        <th className='small-font'>{obj.from} - {obj.to}</th>
                                        <th className='small-font'>{obj.FirstDose}</th>
                                        <th className='small-font'>{obj.SecondDose}</th>
                                        <th className='small-font'>{obj.Date}</th>
                                        <th className='small-font'>{obj.Vaccine}</th>
                                        <th className='small-font'>₹ {obj.Fee_Type}</th>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="small-screen">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col" className='table_heading'>Center</th>
                            <th scope="col" className='table_heading'>Vaccine</th>
                            <th scope="col" className='table_heading'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            details.map((obj, key) => {
                                return (
                                    <tr key={key}>
                                        <th scope="row" className='center-i'>{obj.Name}<br /> <span className='small-font'>{obj.from} - {obj.to}</span> <br /> <span className="small-font green" style={{color: 'white', fontWeight: 'bold'}}>D1: {obj.FirstDose}, D2: {obj.SecondDose}</span></th>
                                        <th className='small-font'>{obj.Vaccine}
                                            <br />
                                            { obj.Age == '18' ? <span className='small-font' style={{ backgroundColor: 'blue', color: 'white', borderRadius: '5px', paddingLeft: '4px', paddingRight: '4px' }}>Age {obj.Age}+</span> : <span className='small-font' style={{ backgroundColor: '#cc7a00', color: 'white', borderRadius: '5px', paddingLeft: '4px', paddingRight: '4px' }}>Age {obj.Age}+</span> }
                                            <br />
                                            <span className='small-font mt-5' data-status={obj.Fee_Type}>                                       
                                            { obj.Fee_Type !== 'Free' ? <span className='fee-paid' style={{color: 'white'}}>₹ {obj.Fee_Type} </span> : <span className='fee-free' style={{color: 'white'}}>₹ {obj.Fee_Type}</span> }
                                            </span>
                                        </th>
                                        <th style={{backgroundColor: 'rgb(139, 194, 73)', width: '50px', textAlign: 'center'}}><span className='book'><a rel='noreferrer nofollow' href="https://selfregistration.cowin.gov.in/" className='not-link'>Book on CoWIN</a></span></th>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <p style={{color: 'red'}}>The slots availability displayed is provided by the public API of CoWIN Portal. The Search Only displays if either Dose 1 or Dose 2 are available. Slot availability displayed here may rapidly change. Dose 1 and Dose 2 are represented using D1 and D2</p>
        </div>
    )
}

export default Details
